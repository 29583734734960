body {
  font-family: "Poppins";
}

.nav-button {
  display: none !important;
}

.remove-img {
  position: relative !important;
  top: -20px !important;
  width: 30px !important;
  height: 30px !important;
  color: black !important;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.48);
  -webkit-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.48);
  position: absolute !important;
  bottom: 0px !important;
  right: 0px !important;
}
.remove-img:hover {
  cursor: pointer;
  scale: 1.2;
}

@media only screen and (min-width: 901px) {
  .dato-contacto-header {
    color: white;
  }

  .menu-mobile {
    display: none !important;
  }

  .buscador {
    min-width: 400px !important;
    width: 60% !important;
    max-width: 700px !important;
    margin-right: 10px !important;
  }

  .buscador input {
    height: 50px !important;
    width: 100% !important;
  }

  .producto-img {
    height: 280px !important;
    width: 100% !important;
  }

  div.card-productos {
    background-color: #126c1ba8 !important;
    border: 2px solid #126c1ba8 !important;
    border-radius: 55px;
    color: white !important;
    border-bottom-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    width: 260px !important;
    height: 400px !important;
    box-shadow: 0px 0px 11px 1px rgb(27 65 26) !important;
    -webkit-box-shadow: 0px 0px 11px 1px rgb(27 65 26) !important;
    -moz-box-shadow: 0px 0px 11px 1px rgb(27 65 26) !important;
    padding: 0px !important;
    margin-top: 20px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
    .producto-categoria {
      position: absolute;
    }
  }

  div.card-productos:hover {
    cursor: pointer;
    background-color: rgb(18 108 27) !important;
  }

  div.card-productos img:hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  .w-20 {
    width: 20% !important;
  }

  .admin .editorClassName {
    height: 350px !important;
    overflow: auto !important;
    padding: 8px !important;
  }
  .producto-nombre {
    font-family: "poppins";
    font-size: 1.2rem !important;
    pointer-events: none !important;
    color: white !important;
  }

  .producto-categoria {
    font-family: "poppins";
    font-size: 0.6rem !important;
    background-color: rgb(75, 199, 20);
    color: white;
    padding: 3px 8px 3px 8px !important;
    border-radius: 15px;
    display: inline !important;
    bottom: 6px;
    pointer-events: none !important;
  }

  .prodcutos-header {
    background-color: #052501;
    padding-top: 180px;
    padding-bottom: 6px;
    h2 {
      color: white;
    }
    input {
      background-color: white;
    }
  }

  .filtros-categorias-wrapper {
    background-color: #000 !important;
  }
  #productos .title {
    padding-bottom: 24px !important;
  }

  header button img {
    width: 18px !important;
  }

  .administrar-login {
    color: #e9ecef17 !important;
    position: fixed;
    top: 4px;
    right: 18px;
    z-index: 10000;
  }

  .administrar-login:hover {
    color: #00ff00 !important;
  }
  .admin {
    padding-top: 180px;
  }

  .admin-content {
    min-height: calc(100vh - 140px);
  }

  .admin .header .title {
    padding-bottom: 0px !important;
  }

  .admin .header .dato-contacto-header {
    cursor: pointer !important;
  }

  .admin .header .title h2 {
    font-weight: 400 !important;
    padding-top: 10px;
    padding-bottom: 20px;
    color: white !important;
    text-transform: capitalize !important;
    font-size: 38px !important;
  }

  .menu-admin {
    margin-top: 32px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 300px;
  }

  .menu-admin-h div,
  .menu-admin ul li {
    padding: 8px;
    margin: 15px;
    border: 1px solid green;
    border-radius: 15px;
    h3 {
      padding-bottom: 0px !important;
    }
  }

  .modal-content {
    background-color: rgb(18 108 27) !important;
    border: 4px solid rgb(18 108 27) !important;
    border-radius: 25px;
    box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.48) !important;
    -webkit-box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.48) !important;
    -moz-box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.48) !important;
    margin-top: auto;
    margin-bottom: auto;
  }

  .modal-img-viewer {
    overflow: hidden;
    .modal-img-main {
      img {
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 8px;
        object-fit: cover;
        max-width: 500px !important;
        max-height: 400px !important;
        transition: opacity 0.5s ease !important;
      }
      .img-main {
        max-width: 500px !important;
        max-height: 400px !important;
      }
    }

    .modal-img-preview-wrapper {
      display: flex !important;
      flex-wrap: wrap !important;
      justify-content: flex-start !important;
      grid-gap: 4px;
      padding-left: 8px;
      padding-bottom: 4px;
      // padding-right: 54px;
      max-width: 500px !important;
      overflow: hidden;
      max-height: 194px;
      justify-content: start !important;
      div {
        margin-top: 0px;
        margin-bottom: 5px;
      }
    }
  }

  .modal-img-preview {
    flex-shrink: 0 !important;
    border: 2px solid #d1d5db;
    width: 90px !important;
    height: 90px !important;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 90px;
      height: 90px;
      object-fit: cover !important;
    }
  }

  .img-main {
    max-width: 600px !important;
    max-height: 500px !important;
  }

  .admin .img-preview {
    max-height: 400px;
    max-width: 400px;
  }

  .modal-img-preview.active {
    border: 4px solid rgb(75, 199, 20);
  }

  .my-modal {
    min-width: 60% !important;
  }

  .modal-description-mobile {
    display: none !important;
  }

  .modal-header svg {
    color: white !important;
  }
  .modal-header .modal-title {
    color: white !important;
    font-weight: 500;
    font-size: 28px !important;
    text-align: center;
  }

  .modal-description {
    color: black !important;
    font-size: 18px !important;
    text-align: left;
  }

  .menu-admin-h div.active,
  .menu-admin-h div:hover,
  .menu-admin ul li:hover {
    background-color: #052501;

    cursor: pointer;
    h3 {
      color: white !important;
      font-weight: 600 !important;
    }
  }

  .btn-clean button {
    padding: 0;
    margin-top: 10px;
    height: 30px !important;
    min-width: 28px !important;
    background-color: #fff !important;
    border: 1px solid #052501 !important;
    color: #052501 !important;
  }

  .btn-clean :hover {
    background-color: #4bc714 !important;
    color: #fff !important;
  }

  .btn-search button {
    padding: 0;
    margin-top: 10px;
    height: 30px !important;
    min-width: 28px !important;
    background-color: #052501 !important;
    border: 1px solid #052501 !important;
    color: #fff !important;
  }

  .btn-search :hover {
    background-color: #4bc714 !important;
    color: #fff !important;
  }

  .dato-contacto-header.active,
  .dato-contacto-header:hover {
    color: #30880d !important;
  }

  .about_box h2 {
    color: white !important;
  }

  .about_img {
    width: 400px !important;
    height: 400px !important;
    margin-top: 40px !important;
  }

  .about_box p {
    color: white !important;
    font-weight: 400 !important;
  }

  .footer .dato-contacto-header {
    font-size: 17px;
  }

  .modal-body {
    background-color: white !important;
    color: black !important;
  }

  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #397524 #dfe9eb;
  }

  /* Chrome, Edge and Safari */
  *::-webkit-scrollbar {
    height: 10px;
    width: 8px;
  }
  *::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #dfe9eb;
  }

  *::-webkit-scrollbar-track:hover {
    background-color: #b8c0c2;
  }

  *::-webkit-scrollbar-track:active {
    background-color: #b8c0c2;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #397524;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #62a34b;
  }

  *::-webkit-scrollbar-thumb:active {
    background-color: #62a34b;
  }

  .whatsapp {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 100;
  }

  .whatsapp-icon {
    margin-top: 13px;
  }

  a.whatsapp :hover {
    color: #37493b !important;
  }

  .offer-bg {
    padding-top: 70px !important;
  }

  .action-icon {
    cursor: pointer;
    opacity: 0.8;
  }
  .action-icon:hover {
    opacity: 1;
  }

  .center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 90vh;
  }

  .text-field {
    min-width: 300px !important;
  }

  .btn-salir {
    color: red !important;
  }

  .btn-salir:hover {
    color: white !important;
  }

  .text-field input {
    height: 50px !important;
  }

  .text-field div {
    height: 50px !important;
    padding-top: 13px !important;
    padding-bottom: 12px !important;
  }

  .listado-productos {
    padding-top: 20px !important;
    padding-left: 35px !important;
    margin-bottom: 80px !important;
    justify-content: space-evenly;
  }

  #myCarousel .img-slider {
    position: relative !important;
    top: -10% !important;
    width: 100vw !important;
    height: 56vw !important;
  }

  .icono-warning {
    margin-bottom: -8px;
    margin-right: 6px;
  }

  #myCarousel .carousel-indicators li {
    margin-bottom: 0px !important;
    margin-top: -15vw !important;
  }

  .about {
    min-height: 100vh !important;
    height: 100% !important;
  }
  .banner-main .carousel-caption h1,
  .banner-main .carousel-caption p,
  .banner-main .carousel-caption span {
    line-height: 1.1em !important;
    text-shadow: black 4px 4px 5px !important;
  }

  .relative {
    top: 50% !important;
  }

  .header .text-field-buscador {
    background-color: white !important;
    border-radius: 10px;
    input {
      height: 39px !important;
      width: 280px !important;
    }
  }

  .header .search button {
    width: 50px;
  }

  .contacto .text-field {
    background-color: white !important;
    border-radius: 8px;
    margin-bottom: 18px;
    width: 100% !important;
    input {
      height: 50px !important;
      width: 100% !important;
    }
  }

  .contact {
    margin-top: 0px !important;
  }

  .contacto .text-field-mensaje {
    background-color: white !important;
    border-radius: 8px;
    margin-bottom: 18px;
    width: 100% !important;
    textarea {
      height: 90px !important;
    }
  }

  header {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0px !important;
    z-index: 1050 !important;
  }

  #contact,
  #about {
    padding-top: 150px !important;
  }

  #contact .title {
    padding-bottom: 30px !important;
    a {
      color: blue;
    }
    p {
      margin-top: 12px !important;
    }
  }

  .search button.btn-buscar {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .search button.btn-buscar:hover {
    background-color: #30880d !important;
  }

  .footer-firma {
    font-size: 12px;
    padding-top: 60px;
    padding-bottom: 10px;
    z-index: 1050 !important;
    bottom: 0px !important;
    left: 0px !important;
    right: 0px !important;
    color: grey !important;
    a {
      color: grey !important;
    }
  }
  .footer-firma a:hover {
    color: #62a34b !important;
  }

  .footer {
    padding-bottom: 0px !important;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: black;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    max-height: calc(100vh - 180px) !important;
    overflow-y: auto !important;
  }

  .dropdown-content a {
    cursor: pointer;
    float: none;
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    border-bottom: 2px solid grey;
  }

  .filtros-categorias div.active,
  .filtros-categorias div.filtros-categorias-item:hover,
  .dropdown-content a:hover {
    color: white !important ;
    background-color: #4bc714;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .filtros-categorias {
    position: sticky !important;
    top: 200px !important;
    background-color: #000 !important;
    min-width: 18vw !important;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2) !important;
    div.filtros-categorias-item {
      cursor: pointer !important;
      padding: 12px 20px 12px 40px !important;
      text-decoration: none !important;
      display: block !important;
      text-align: left !important;
      font-size: larger !important;
      font-weight: 500;
      border-bottom: 2px solid grey;
      span {
        display: inline-block !important;
      }
      h3 {
        color: white !important;
      }
    }
  }

  .opaco-7 {
    opacity: 0.7 !important;
  }

  .btn-clean-categoria {
    display: block !important;
    width: 50px !important;
    cursor: pointer !important;
    margin-top: 4px !important;
    margin-bottom: -4px !important;
    color: white !important;
    z-index: 1 !important;
  }

  .loading-backdrop {
    background-color: white;
    width: 100vw !important;
    height: 100vh !important;
    text-align: center;
    img {
      margin-left: auto;
      margin-right: auto;
      margin-top: 40vh !important;
    }
  }

  .modal-footer .buynow {
    width: 116px !important;
    padding: 0px 1px 0px 1px !important;
    cursor: pointer !important;
    background-color: white !important;
    border-color: white !important;
    color: black !important;
    padding: 6px 4px 6px 4px !important;
  }
  .modal-footer .buynow:hover {
    background-color: #30880d !important;
    border-color: #30880d !important;
    color: white !important;
  }

  .buynow {
    cursor: pointer !important;
  }

  .editor-wysiwyg {
    border: 1px solid grey;
    min-height: 400px;
  }
}

@media only screen and (max-width: 900px) {
  .administrar-login,
  .header .dato-contacto-header,
  .menu-area-main,
  .filtros-categorias,
  .vias-contacto,
  #about,
  #contact,
  .footer-navegacion,
  .footer-firma,
  .menu-principal,
  .modal-description,
  .modal-img-preview-wrapper {
    display: none !important;
    overflow-y: hidden !important;
  }

  .nav-button {
    display: block !important;
    border-radius: 100% !important;
    width: 3rem !important;
    height: 3rem !important;
  }

  .nav-button.right-button {
    position: absolute !important;
    top: 50% !important;
    right: 20px !important;
    background-image: url("./../assets/flecha_siguiente.png") !important;
  }

  .nav-button.left-button {
    position: absolute !important;
    top: 50% !important;
    left: 20px !important;
    background-image: url("./../assets/flecha_anterior.png") !important;
  }

  .footer .footer-contacto {
    margin-left: 20px !important;
  }

  .footer .dato-contacto-header {
    display: block !important;
    color: white !important;
  }
  .menu-mobile {
    display: block !important;
    position: fixed !important;
    top: 28px !important;
    left: 16px !important;
    z-index: 1050 !important;
  }

  .mobile.dropdown .dropdown-content {
    position: fixed;
    display: block;
    width: 100vw;
    top: 100px;
    z-index: 1616 !important;
    a {
      color: white !important;
      font-size: large;
    }
  }
  .header {
    padding: 10px !important;
  }

  .header img {
    width: 50vw !important;
    margin: auto !important;
    clear: both !important;
  }

  .header .row {
    justify-content: center !important;
  }

  .buscador {
    width: 80% !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .buscador input {
    height: 50px !important;
    width: 100% !important;
  }

  .footer {
    padding-bottom: 60px !important;
  }

  .producto-img {
    height: 280px !important;
    width: 100% !important;
  }

  div.card-productos {
    background-color: #052501 !important;
    border: 5px solid #052501 !important;
    border-radius: 25px;
    color: white !important;

    border-bottom-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    width: 75vw !important;
    margin-bottom: 22px !important;
    height: 400px !important;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.48);
    -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.48);
    padding: 0px !important;
  }

  div.card-productos :hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  .w-20 {
    width: 20% !important;
  }

  .producto-nombre {
    font-family: "poppins";
    font-size: 1.2rem !important;
    color: white !important;
  }

  .producto-categoria {
    font-family: "poppins";
    font-size: 0.6rem !important;
    background-color: rgb(75, 199, 20);
    color: white;
    padding: 3px 8px 3px 8px !important;
    border-radius: 15px;
    display: inline !important;
    position: absolute;
    bottom: 6px;
    pointer-events: none !important;
  }

  #productos .title {
    padding-bottom: 24px !important;
  }

  header button img {
    width: 18px !important;
  }

  .btn-clean button {
    padding: 0;
    margin-top: 10px;
    height: 30px !important;
    min-width: 28px !important;
    background-color: #fff !important;
    border: 1px solid #052501 !important;
    color: #052501 !important;
  }

  .btn-clean :hover {
    background-color: #4bc714 !important;
    color: #fff !important;
  }

  .btn-search button {
    padding: 0;
    margin-top: 10px;
    height: 30px !important;
    min-width: 28px !important;
    background-color: #052501 !important;
    border: 1px solid #052501 !important;
    color: #fff !important;
  }

  .btn-search :hover {
    background-color: #4bc714 !important;
    color: #fff !important;
  }

  .dato-contacto-header:hover {
    color: #30880d !important;
  }

  .footer .dato-contacto-header {
    font-size: 17px;
  }

  .whatsapp {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 24px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 100;
  }

  .whatsapp-icon {
    margin-top: 13px;
  }

  a.whatsapp :hover {
    color: #37493b !important;
  }

  .offer-bg {
    padding-top: 70px !important;
  }

  .action-icon {
    cursor: pointer;
    opacity: 0.8;
  }
  .action-icon:hover {
    opacity: 1;
  }

  .center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 90vh;
  }

  .text-field {
    min-width: 300px !important;
  }

  .text-field input {
    height: 50px !important;
  }

  .text-field div {
    height: 50px !important;
    padding-top: 13px !important;
    padding-bottom: 12px !important;
  }

  .listado-productos {
    margin-bottom: 80px !important;
  }

  #myCarousel .img-slider {
    position: relative !important;
    top: 0 !important;
    width: 100vw !important;
    height: 56vw !important;
  }

  .icono-warning {
    margin-bottom: -8px;
    margin-right: 6px;
  }

  #myCarousel {
    position: relative;
    top: 90px !important;
  }
  #myCarousel .carousel-indicators li {
    width: 15px;
    height: 15px;
    margin-bottom: 0px !important;
    margin-top: 15vw !important;
  }

  .banner-main .carousel-caption h1 {
    font-size: x-large;
  }
  .banner-main .carousel-caption span {
    font-size: large;
  }
  .banner-main .carousel-caption h1,
  .banner-main .carousel-caption p,
  .banner-main .carousel-caption span {
    line-height: 1em !important;
    text-shadow: 4px 4px 20px black !important;
  }

  .relative {
    top: 45% !important;
  }

  .header .text-field-buscador {
    background-color: white !important;
    border-radius: 10px;
    input {
      height: 39px !important;
      width: 280px !important;
    }
  }

  .header .search button {
    width: 50px;
  }

  header {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0px !important;
    z-index: 1049 !important;
  }

  #productos {
    padding-top: 80px !important;
  }

  .search button.btn-buscar {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .search button.btn-buscar:hover {
    background-color: #30880d !important;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: black;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    max-height: calc(100vh - 180px) !important;
    overflow-y: auto !important;
  }

  .dropdown-content a {
    cursor: pointer;
    float: none;
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    border-bottom: 2px solid grey;
  }

  .filtros-categorias div.active,
  .filtros-categorias div.filtros-categorias-item:hover,
  .dropdown-content a:hover {
    color: white !important ;
    background-color: green;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .filtros-categorias {
    position: sticky !important;
    top: 200px !important;
    background-color: black !important;
    min-width: 18vw !important;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2) !important;
    div.filtros-categorias-item {
      cursor: pointer !important;
      padding: 12px 20px 12px 40px !important;
      text-decoration: none !important;
      display: block !important;
      text-align: left !important;
      font-size: larger !important;
      font-weight: 500;
      border-bottom: 2px solid grey;
      h3 {
        color: white !important;
      }
      span {
        display: inline-block !important;
      }
    }
  }

  .opaco-7 {
    opacity: 0.7 !important;
  }

  .btn-clean-categoria {
    display: block !important;
    width: 50px !important;
    cursor: pointer !important;
    margin-top: 4px !important;
    margin-bottom: -4px !important;
    color: white !important;
    z-index: 1 !important;
  }

  .loading-backdrop {
    background-color: white;
    width: 110vw !important;
    height: 110vh !important;
    text-align: center;
    img {
      margin-left: auto;
      margin-right: auto;
      margin-top: 40vh !important;
    }
  }

  .buynow {
    width: 116px !important;
    padding: 0px 1px 0px 1px !important;
    cursor: pointer !important;
    background-color: #30880d !important;
    border-color: #30880d !important;
    color: white !important;
  }

  .modal-description-mobile {
    justify-content: center;
    .modal-description {
      display: block !important;
    }
  }

  .modal-content {
    background-color: rgb(18 108 27) !important;
    border: 3px solid #4ac71486 !important;
    border-radius: 25px;
    box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.48) !important;
    -webkit-box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.48) !important;
    -moz-box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.48) !important;
    margin-top: auto;
    margin-bottom: auto;
  }
  .modal-header .modal-title {
    color: white !important;
    font-weight: 500;
    font-size: 24px !important;
    text-align: center;
  }

  .modal-body {
    background-color: white !important;
    color: black !important;
  }

  .modal-description {
    color: white !important;
    font-size: 16px !important;
    text-align: left;
    margin: 10px !important;
  }

  .prodcutos-header .container {
    padding-top: 12px !important;
  }

  .modal-header svg {
    color: white !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header img[alt="logo agro bolsas"] {
    width: 120px !important;
  }
  .vias-contacto {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .search {
    margin-right: 0px !important;
  }
}
